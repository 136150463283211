<template>
  <div>
    加载中...
  </div>
</template>

<script>
// import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
// import Keyboard from "./../keyboard/keyboard";  // 手写键盘
import { getCompany, getCompanyGroup } from '@/api/api'
import config from "@/config";
import CryptoJS from 'crypto-js'
export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // components: { MyDialog, Keyboard },
  data: function (){
    return {
      merchantName: '',  // 付款的商户默认
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      resData : {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      appId: '',
      mchNo: '',
      openid: '',
      groupId: ''
    }
  },

  mounted() {
      this.appId = this.$route.params.appId
      this.groupId = this.$route.params.groupId
      if(this.groupId && this.groupId !== ''){
          this.getCompanyGroupInfo(this.groupId)
      } else {
          this.getCompanyInfo(this.appId)
      }
  },

  methods: {
    getCompanyInfo(appId){
        const that = this
        getCompany(appId).then(res => {
            that.merchantName = res.mchShortName
            that.appId = res.appId
            that.mchNo = res.mchNo
            that.openUrl()
        }).catch(res => {
            that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
        });
    },
    getCompanyGroupInfo(groupId){
        const that = this
        getCompanyGroup(groupId).then(res => {
            that.merchantName = res.mchShortName
            that.appId = res.appId
            that.mchNo = res.mchNo
            that.openUrl()
        }).catch(res => {
            that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
        });
    },
    openUrl(){
        if(this.merchantName === '' || this.appId === ''){
            alert('没有支付信息！');
            return;
        }

        const timestamp = new Date().getTime();
        let params = {
            'mchNo': this.mchNo,
            'appId': this.appId,
            'ifCode': 'alipay',
            'redirectUrl': 'https://payment.sybpay.cn/#/hubECompany/'+this.appId,
            // 'redirectUrl': 'http://192.168.8.29:8080/#/hubECompany/'+this.appId,
            'reqTime': timestamp,
            'version': '1.0',
            'openType': 'self',
            'signType': 'MD5'
        }

        const sortedObj = Object.keys(params).sort().reduce((acc, key) => {
            acc[key] = params[key];
            return acc;
        }, {});

        console.log(1111)
        console.log(sortedObj)

        let tempArr = []
        for (let key in sortedObj) {
            let tempStr = key + '=' + params[key]
            tempArr.push(tempStr)
        }

        let singStr = tempArr.join('&')

        let singStrSign = singStr + '&key=123456'

        console.log(2222222222222)
        console.log(singStrSign)
        // alert(singStrSign)

        const md5Hash = CryptoJS.MD5(singStrSign).toString();

        params.sign = md5Hash

        singStr = singStr + '&sign=' + md5Hash
        singStr = singStr.replace('#','%23')

        //https://api.payment.sybpay.cn/api/channelUserId/jump?appId=665bdeb1e4b004c4db5198ab&ifCode=wxpay&mchNo=M1717296816&redirectUrl=http://sybpay.cn&reqTime=1622011236571&signType=MD5&version=1.0&sign=F17046F0DCCD46A4E9F8D669835DEEE1

        // console.log(params)
        // console.log(singStr)
        // console.log(111111111111)
        // console.log('http://localhost:9216/api/channelUserId/jump?'+singStr)
        // window.location.href = 'http://localhost:9216/api/channelUserId/jump?'+singStr
        // window.location.href = 'http://192.168.8.29:9216/api/channelUserId/jump?'+singStr
        window.location.href = 'https://api.payment.sybpay.cn/api/channelUserId/jump?'+singStr
        // console.log(2222)
    }

  }


}
</script>
<style lang="css" scoped>
 @import './pay.css';
</style>